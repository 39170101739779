// Libs
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Components
import Link from '@components/Link';

// Utils
import { isUrlAbsolute } from '@utils/url';
import { log } from '@hooks/logger';

// Types
import { LogLevel } from '@typings/graphql';

export type SiteSearchResultCDALinksProps = {
  item_id: string;
};

const SiteSearchResultCDALinks: React.FC<SiteSearchResultCDALinksProps> = ({
  item_id,
}) => {
  const { i18n } = useTranslation();
  const [links, setLinks] = useState(new Array(0));

  useEffect(() => {
    const cdaLinksUrl = `${process.env.GATSBY_BACKEND_APP_BASE_URL}/${i18n.language}/restapi/cda/links/${item_id}`;

    axios
      .get(cdaLinksUrl, {
        withCredentials: true,
      })
      .then(response => {
        log({
          level: LogLevel.Debug,
          message: `Drupal: get CDA links, request to ${cdaLinksUrl}`,
          other: {
            requestData: {
              withCredentials: true,
            },
            response,
          },
        });
        setLinks(response.data);
      });
  }, []);

  return (
    <ul>
      {links &&
        links.map((item: any) => {
          return item.path ? (
            <li key={item.id}>
              {isUrlAbsolute(item.path) ? (
                <a href={item.path}>{item.title}</a>
              ) : (
                <Link to={item.path}>{item.title}</Link>
              )}
            </li>
          ) : null;
        })}
    </ul>
  );
};

export default SiteSearchResultCDALinks;
