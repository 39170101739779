// Source id => Base URL mapping
interface SourceIdUrlMap {
  [key: string]: string;
}

export const sourceIdUrlMap: SourceIdUrlMap = {
  rotary_international_convention: 'http://riconvention.org',
  rotary: 'https://www.rotary.org',
  end_polio: 'https://www.endpolio.org',
  my_rotary_legacy_app: 'https://my.rotary.org',
  my_rotary_web_app: 'https://my.rotary.org',
};
