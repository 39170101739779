import { uiLanguages } from '../../languages';

export type AggregatedFacet = {
  value: string;
  label: string;
  count: number;
  isDisabled?: boolean;
};

export type SolrFacet = {
  value: string;
  count: number;
};
/**
 * Formats a facet to be displayed in a select (options) element
 *
 * @param facet_name
 * @param facet
 * @param approx
 *  Flag to mark whether the facet is approximate or not.
 */
export const formatFacetOptions = (
  facet_name: string,
  facet: SolrFacet[],
  approx?: boolean,
  defaultValues?: AggregatedFacet[]
) => {
  let facets = [];
  // Exception for document_mimetype facets: these facets are aggregated.
  if (facet_name === 'document_mimetype' && defaultValues) {
    const aggregatedFacets: AggregatedFacet[] = defaultValues;
    const facetIsAggregated: string[] = [];
    const incrementFacetCount = (
      currentCount: string | number,
      additionalCount: string | number
    ) => {
      const a =
        typeof currentCount !== 'number'
          ? parseInt(currentCount)
          : currentCount;
      const b =
        typeof additionalCount !== 'number'
          ? parseInt(additionalCount)
          : additionalCount;
      return a + b;
    };
    facet.map(item => {
      aggregatedFacets.map(aggregatedFacet => {
        const negate = aggregatedFacet.value.substring(0, 1) === '!';
        const conditionalMatch = aggregatedFacet.value.includes('||');

        const mimetypeMatch = (value: string, condition: string) => {
          const exp = `${condition.trim().replace('/*', '\\/\\S+')}`;
          const regex = new RegExp(exp);
          return (
            condition.trim().includes(value.trim()) || regex.test(value.trim())
          );
        };

        if (!negate) {
          // Simple mimetype mapping: either one specific mimetype or one pattern eg video/*
          if (
            !conditionalMatch &&
            mimetypeMatch(item.value, aggregatedFacet.value)
          ) {
            aggregatedFacet.count = incrementFacetCount(
              aggregatedFacet.count,
              item.count
            );
            facetIsAggregated.push(item.value);
          } else if (conditionalMatch) {
            // turn into array and match regex or directly
            const conditions = aggregatedFacet.value.split('||');
            conditions.map(condition => {
              if (mimetypeMatch(item.value, condition)) {
                aggregatedFacet.count = incrementFacetCount(
                  aggregatedFacet.count,
                  item.count
                );
                facetIsAggregated.push(item.value);
              }
            });
          }
        }
      });
    });
    facet
      .filter(facetItem => !facetIsAggregated.includes(facetItem.value))
      .map(item => {
        aggregatedFacets[
          aggregatedFacets.length - 1
        ].count = incrementFacetCount(
          aggregatedFacets[aggregatedFacets.length - 1].count,
          item.count
        );
      });

    aggregatedFacets.map(facet => {
      facet.label = `${facet.label} (${facet.count})`;
      facet.isDisabled = facet.count == 0;
    });
    facets = aggregatedFacets;
  } else {
    facets = facet.map((item: any) => {
      return {
        value: item.value,
        label: `${formatFacetOptionLabel(item.value, facet_name)} (${
          approx === true ? `~` : ``
        }${item.count})`,
        isDisabled: item.count == 0,
      };
    });
  }

  return facets;
};

/**
 * Formats a facet option label for displaying it in the frontend.
 * @param label
 * @param facet_name
 */
const formatFacetOptionLabel = (label: string, facet_name: string) => {
  // Special handling for langcodes, we actually want to display the language
  // name, not the language code.
  if (facet_name === 'langcode') {
    const languages = uiLanguages();
    // Only return the ui language if there is a value for it. Otherwise, just
    // fallback to the default implementation (return the label itself).
    if (typeof languages[label] !== 'undefined') {
      return languages[label];
    }
  }
  return `${label.charAt(0).toUpperCase()}${label.slice(1).replace('_', ' ')}`;
};
