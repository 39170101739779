import React, { forwardRef } from 'react';

import { useTranslation } from '@external/react-i18next';

type SiteSearchSummaryProps = {
  showing: number;
  total: number;
  numberOfFilters?: number;
  name?: string;
  email?: string;
  children: React.ReactNode;
};

const SiteSearchSummary = forwardRef<
  HTMLParagraphElement,
  SiteSearchSummaryProps
>(({ showing, total, name, email, numberOfFilters, children }, ref) => {
  let nameEmail: string | undefined;
  if (name || email) {
    // Use "name, email" if both are given or just one.
    nameEmail = [name, email].filter(Boolean).join(', ');
  }

  const { t } = useTranslation();

  return (
    <div className="text-xs leading-xs mb-0" ref={ref}>
      {t(
        'search.resultsSummary.showing',
        'Showing {{ showing }} of {{ total }} results',
        { showing, total, count: total }
      )}{' '}
      {nameEmail &&
        t(
          'search.resultsSummary.for-html',
          'for <strong>{{ nameEmail }}</strong>',
          { nameEmail }
        )}{' '}
      {!!numberOfFilters &&
        t(
          'search.resultsSummary.withFiltersApplied-html',
          'with <strong>{{ numberOfFilters }}</strong> filter applied',
          { numberOfFilters, count: numberOfFilters }
        )}
      {children}
    </div>
  );
});

export default SiteSearchSummary;
