import React, { useEffect, useReducer } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import { Button } from '@components/Button';
import Select from '@components/Formik/Select';
import TextField from '@components/Formik/TextField';

import {
  SiteSearchFacets,
  SiteSearchFormProps,
  SiteSearchFormValues,
} from '@domain/search';

import { getFileTypeFacets } from '@use-cases/clubs';
import {
  addQueryParamsToURL,
  getSiteParamsFromURL,
  validateSiteParamsFromURL,
} from '@use-cases/districts';
import { useNotifications } from '@use-cases/notifications';
import { mapSolrFacetFieldsToSiteSearchFacets } from '@use-cases/search/helpers';

import { addLocalisationToPath } from '@utils/localized-navigate';
import { AggregatedFacet, formatFacetOptions, SolrFacet } from '@utils/search';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase } from '@external/react-select';

const SiteSearchForm: React.FC<SiteSearchFormProps> = ({
  activeFilters,
  setActiveFilters,
  filters,
  setFilters,
  defaultFilters,
  facets,
  setCurrentPage,
}) => {
  const { t, i18n } = useTranslation();
  const { addError, clearNotification } = useNotifications();

  const currentLanguage = i18n.language;

  const noFiltersError = t(
    'site-search.error.no-search-term',
    'Please enter a search term.'
  );

  const defaultFilterOptions = {
    contentType: [],
    documentMimetype: [],
    langcode: [],
    topic: [],
  };

  const [filterOptions, setFilterOptions] = useReducer(
    (state: SiteSearchFacets, action: SiteSearchFacets) => ({
      ...state,
      ...action,
    }),
    defaultFilterOptions
  );

  const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
  const baseClubURL = `${appUrl}${addLocalisationToPath('/site-search')}`;
  const initialValuesfromURL = getSiteParamsFromURL();
  const validatedInitialValues = validateSiteParamsFromURL(
    initialValuesfromURL
  );

  const handleChange = (input: SiteSearchFormValues) => {
    setFilters(input);
    const formattedURL = addQueryParamsToURL(baseClubURL, {
      ...filters,
      ...input,
    });
    window.history.pushState({}, '', formattedURL);
  };

  const resetFilters = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newFilters = { ...defaultFilters, langcode: filters.langcode };
    setFilters(newFilters);
    setActiveFilters(newFilters);
    setCurrentPage(1);
    const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
    const siteSearchURL = `${appUrl}${addLocalisationToPath('/site-search')}`;
    window.history.pushState({}, '', siteSearchURL);
  };

  const refreshSearch = (input: SiteSearchFormValues) => {
    if (typeof input.keywords === 'undefined' || input.keywords === '') {
      addError(noFiltersError, { id: 'form.no-filters-error' });
      return;
    }

    clearNotification('form.no-filters-error');
    setCurrentPage(1);

    // Filters (except language and keywords) are reset, if keyword search is changed.
    if (input.keywords !== activeFilters.keywords) {
      const sessionFilters = Object.assign(defaultFilters, {
        keywords: input.keywords,
        langcode: input.langcode,
      });

      setFilters(sessionFilters);
      setActiveFilters(sessionFilters);
    } else {
      // Otherwise, we're updating all the filters
      setFilters(input);
      setActiveFilters(input);
    }
  };

  const initialValues = {
    keywords: filters.keywords,
    contentType: filters.contentType,
    documentMimeType: filters.documentMimeType,
    topic: filters.topic,
    langcode: filters.langcode,
  };

  const fileTypeFacetDefaultValues = getFileTypeFacets(t);
  const mappedFacets = mapSolrFacetFieldsToSiteSearchFacets(facets);
  useEffect(() => {
    setFilters({ langcode: currentLanguage });
    // Search whenever currentLanguage changes.
  }, [currentLanguage]);

  // When the facets get updated, update the filter options as well.
  useEffect(() => {
    if (mappedFacets) {
      setFilterOptions({
        contentType: ((mappedFacets.contentType &&
          formatFacetOptions(
            'content_type',
            mappedFacets.contentType as SolrFacet[]
          )) ||
          []) as AggregatedFacet[],
        documentMimetype: ((mappedFacets.documentMimetype &&
          formatFacetOptions(
            'document_mimetype',
            mappedFacets.documentMimetype as SolrFacet[],
            false,
            fileTypeFacetDefaultValues
          )) ||
          []) as AggregatedFacet[],
        langcode: ((mappedFacets.langcode &&
          // Mark the facet count for language as approximate if we have no language set,
          // as when filtering by a language, stemming produces more results.
          formatFacetOptions(
            'langcode',
            mappedFacets.langcode as SolrFacet[],
            activeFilters.langcode === ''
          )) ||
          []) as AggregatedFacet[],
        topic: ((mappedFacets.topic &&
          formatFacetOptions(
            'topic',
            mappedFacets.topic as AggregatedFacet[]
          )) ||
          []) as AggregatedFacet[],
      });
    }
  }, [facets]);

  // Whenever one of the filters (except the keywords) is updated, we want to
  // re-run the query.
  useEffect(() => {
    setCurrentPage(1);
    setActiveFilters(filters);
  }, [
    filters.contentType,
    filters.topic,
    filters.langcode,
    filters.documentMimeType,
  ]);

  return (
    <Formik
      initialValues={
        isEmpty(validatedInitialValues) ? initialValues : validatedInitialValues
      }
      onSubmit={(values, helpers) => {
        refreshSearch(values);
        helpers.setSubmitting(false);
        const formattedURL = addQueryParamsToURL(baseClubURL, values);
        window.history.pushState({}, '', formattedURL);
      }}
      enableReinitialize
    >
      {() => {
        return (
          <Form>
            <TextField
              name="keywords"
              label={t('search.site.keywords-label', 'Search')}
              placeholder={t(
                'search.site.keywords-placeholder',
                'Search Rotary International'
              )}
              resettable
              icon="magnifier"
            />
            <Button full>
              {t('search.test-page.search-label', 'Search ')}
            </Button>
            {activeFilters.keywords !== '' && (
              <>
                <h4 className="mt-10 mb-5 alternate">
                  {t('search.site.filters-label', 'Filter by')}
                </h4>
                <Select
                  name="contentType"
                  label={t('search.site.filters-type-label', 'Type')}
                  value={(filterOptions.contentType as AggregatedFacet[]).filter(
                    (option: OptionTypeBase) =>
                      option.value === filters.contentType
                  )}
                  placeholder={t(
                    'search.site.select-default-placeholder',
                    'Select...'
                  )}
                  clearable
                  options={filterOptions.contentType as AggregatedFacet[]}
                  onChange={selectedOption =>
                    handleChange({
                      contentType: selectedOption,
                    })
                  }
                />
                {filters.contentType === 'document' && (
                  <Select
                    name="documentMimeType"
                    label={t(
                      'search.site.filters-file-type-label',
                      'File type'
                    )}
                    clearable
                    value={(filterOptions.documentMimetype as AggregatedFacet[])?.filter(
                      (option: OptionTypeBase) =>
                        option.value === filters.documentMimeType
                    )}
                    placeholder={t(
                      'search.site.select-default-placeholder',
                      'Select...'
                    )}
                    options={
                      filterOptions.documentMimetype as AggregatedFacet[]
                    }
                    onChange={selectedOption =>
                      handleChange({
                        documentMimeType: selectedOption,
                      })
                    }
                  />
                )}
                {(filterOptions.topic as AggregatedFacet[]).length > 0 &&
                  activeFilters.keywords !== '' && (
                    <Select
                      name="topic"
                      label={t('search.site.filters-topic-label', 'Topic')}
                      clearable
                      value={(filterOptions.topic as AggregatedFacet[]).filter(
                        (option: OptionTypeBase) =>
                          option && option.value === filters.topic
                      )}
                      placeholder={t(
                        'search.site.select-default-placeholder',
                        'Select...'
                      )}
                      options={filterOptions.topic as AggregatedFacet[]}
                      onChange={selectedOption =>
                        handleChange({
                          topic: selectedOption,
                        })
                      }
                    />
                  )}
                <Select
                  name="langcode"
                  label={t('search.site.filters-language-label', 'Language')}
                  clearable
                  value={(filterOptions.langcode as AggregatedFacet[])?.filter(
                    (option: OptionTypeBase) =>
                      option && option.value === filters.langcode
                  )}
                  placeholder={t(
                    'search.site.select-default-placeholder',
                    'Select...'
                  )}
                  options={filterOptions.langcode as AggregatedFacet[]}
                  onChange={selectedOption =>
                    handleChange({
                      langcode: selectedOption,
                    })
                  }
                />
                <div className="mt-6 text-center min-w-full">
                  <span className="link-styles" onClick={resetFilters}>
                    {t('search.site.filters-reset-label', 'Reset All Filters')}
                  </span>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SiteSearchForm;
