// Libs
import React from 'react';
import moment from 'moment';

// Hooks
import { useAnalytics } from '@hooks/analytics';
import { useTranslation } from '@external/react-i18next';

// Types
import { SearchSiteResultRowFragment } from '@typings/operations';

// Components
import Link from '@components/Link';
import SiteSearchResultCDALinks from './SiteSearchResultCDALinks';

// Utils
import { isUrlAbsolute } from '@utils/url';
import { bytesToSize } from '@utils/bytesToSize';
import { getItemSourceUrl } from '@use-cases/clubs';
import { htmlEntitiesDecode } from '@utils/string-helpers';
import { sourceIdUrlMap } from './constants';

export type SiteSearchResultProps = SearchSiteResultRowFragment & {
  searchRank: number;
};

const SiteSearchResult: React.FC<SiteSearchResultProps> = ({
  title_fulltext,
  source_id,
  source_path,
  content_fulltext,
  date_modified,
  document_file_size,
  item_id,
  langcode,
  site,
  bundle,
  searchRank,
}) => {
  const { t } = useTranslation();
  const { fireTrackingEvent } = useAnalytics();

  // If the search result is not a Gatsby link or external,
  // prepend the path with the correct base URL.
  const item_source_url = getItemSourceUrl(site, source_id, source_path || '');
  const item_langcode = langcode || '';

  // Trim any trailing whitespaces to make sure ellipsis works nicely.
  const content = content_fulltext.toString().trim();
  const item_title = htmlEntitiesDecode(title_fulltext);

  const trackClick = (destination: string) => {
    fireTrackingEvent('searchResultClicked', {
      searchResultDestination: destination,
      searchType: 'Site Search',
      searchResultIndex: searchRank,
    });
  };

  const getSearchResult = () => {
    if (!source_path) {
      return <h3 className="mb-1 font-bold">{item_title}</h3>;
    }

    const sourceUrlToPrint = `${item_source_url.substr(0, 47)}...`;

    if (isUrlAbsolute(item_source_url)) {
      return (
        <>
          <a href={item_source_url} onClick={() => trackClick(item_source_url)}>
            <h3 className="mb-1 font-bold">{item_title}</h3>
          </a>

          <a
            href={item_source_url}
            className="mb-1 text-sm"
            onClick={() => trackClick(item_source_url)}
          >
            {sourceUrlToPrint}
          </a>
        </>
      );
    }

    return (
      <>
        <Link
          to={item_source_url}
          lang={item_langcode}
          onClick={() => trackClick(item_source_url)}
        >
          <h3 className="mb-1 font-bold">{item_title}</h3>
        </Link>

        <Link
          to={item_source_url}
          lang={item_langcode}
          onClick={() => trackClick(item_source_url)}
        >
          {sourceUrlToPrint}
        </Link>
      </>
    );
  };

  return (
    <div data-label="SearchResult" className="flex w-full">
      <div className="self-stretch">
        {getSearchResult()}

        <p
          className="mt-1 mb-2 text-sm"
          dangerouslySetInnerHTML={{
            __html: `${content}${content.length >= 235 ? '...' : ''}`,
          }}
        />

        {bundle === 'cda_link' && (
          <SiteSearchResultCDALinks item_id={item_id || ''} />
        )}

        <div className="tablet:flex text-gray-400 text-xs mt-6">
          <p className="mb-0">
            {t('search.site-search.updated-label', 'Updated')}{' '}
            {moment(date_modified).format('D MMM YYYY')}{' '}
            {document_file_size &&
              `${' | '} ${t(
                'search.site-search.size-label',
                'Size'
              )} ${bytesToSize(document_file_size)}`}
            {` | ${sourceIdUrlMap[source_id]}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SiteSearchResult;
