import React, { useEffect, useRef, useState } from 'react';

import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import SiteSearchResult from './SiteSearchResult';
import SiteSearchSummary from './SiteSearchSummary';

import { SiteSearchListProps } from '@domain/search';

import { useErrorHandling } from '@use-cases/notifications';

import { useSiteSearch } from '@repositories/clubs';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';

import { SiteSearchResults, SolrResultItem } from '@typings/operations';

const SiteSearchList: React.FC<SiteSearchListProps> = ({
  activeFilters,
  setActiveFilters,
  currentPage,
  setCurrentPage,
  setFacets,
  setFilters,
}) => {
  const topOfTheResultList = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { fireTrackingEvent } = useAnalytics();
  const formattedActiveFilters = {
    ...activeFilters,
    content_type: activeFilters.contentType,
    document_mimetype: activeFilters.documentMimeType,
  };
  const pageSize = 4;

  const [results, setResults] = useState<Array<SolrResultItem>>([]);
  const [resultCount, setResultCount] = useState<number>(0);
  const [suggestion, setSuggestion] = useState<string>('');
  const { data, loading, error } = useSiteSearch(
    formattedActiveFilters,
    currentPage,
    pageSize
  );

  useErrorHandling(error?.message, !!error, 'site-search.error');

  const numberOfFilters: number = Object.entries(activeFilters).filter(
    ([key, value]) => value && !['keywords'].includes(key)
  ).length;

  const noFiltersError = t(
    'member-search.error.no-filters',
    'Please provide at least one search parameter.'
  );

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const searchSuggestion = () => {
    if (suggestion !== undefined) {
      setFilters({ keywords: suggestion });
      setActiveFilters(Object.assign(activeFilters, { keywords: suggestion }));
      setSuggestion('');
    }
  };

  useEffect(() => {
    if (data) {
      const solrResponse = data.searchSolr as SiteSearchResults;
      setFacets(solrResponse.facet_counts.facet_fields);

      setResults(solrResponse.response.docs);
      setResultCount(solrResponse.response.numFound);
      if (solrResponse.spellcheckSuggestion) {
        setSuggestion(solrResponse.spellcheckSuggestion);
      }

      // Fire analytics event when we have the initial search results and a search term.
      if (activeFilters.keywords && currentPage === 1) {
        if (solrResponse.response.numFound !== 0) {
          fireTrackingEvent('searchResults', {
            searchTerm: activeFilters.keywords,
            searchTotalResults: solrResponse.response.numFound,
            searchType: 'Site Search',
            searchLanguage: activeFilters.langcode,
            searchFilters: activeFilters,
          });
        } else {
          // Separate out the event if zero results
          fireTrackingEvent('searchResultsEmpty', {
            searchTerm: activeFilters.keywords,
            searchType: 'Site Search',
            searchLanguage: activeFilters.langcode,
            searchFilters: activeFilters,
          });
        }
      }
    }

    // Scroll to top when data is updated
    topOfTheResultList.current?.scrollIntoView();
  }, [data]);

  if (!activeFilters.keywords) {
    return <p>{noFiltersError}</p>;
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <ResultsList
        listId="search-results-list"
        summary={
          <SiteSearchSummary
            showing={results.length}
            total={resultCount}
            numberOfFilters={numberOfFilters}
            name={activeFilters.keywords}
            ref={topOfTheResultList}
          >
            {results && !results.length && suggestion && (
              <p className="mt-4 mb-2">
                Search instead for:{' '}
                <span
                  className="link-styles"
                  onClick={() => searchSuggestion()}
                >
                  {suggestion}
                </span>{' '}
              </p>
            )}
          </SiteSearchSummary>
        }
      >
        {results.map((item, index) => (
          <ResultsListItem key={item?.id || index}>
            {/* Add the "search rank" to results (which number link is clicked in results) */}
            <SiteSearchResult
              {...item}
              searchRank={pageSize * (currentPage - 1) + (index + 1)}
            />
          </ResultsListItem>
        ))}
      </ResultsList>

      {resultCount > pageSize && (
        <Pagination
          pageSize={pageSize}
          page={currentPage}
          totalCount={resultCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};

export default SiteSearchList;
